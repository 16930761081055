import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

const Star = (
  <svg
    className="w-4 h-4"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="#F68B1F"
  >
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
);

function RecentProjectTile({ project }) {
  let { frontmatter } = project.node;
  let { title, description, slug, city, zip, testimonial, excerpt } =
    frontmatter;

  let showImages = false;
  let showMaxImageCount = 0;
  let projectImages = [];

  //check if project images exist, and if more than 4, slice array to only for
  //since that is max we show for project tile preview
  if (project.node.frontmatter.images) {
    showImages = true;
    showMaxImageCount = project.node.frontmatter.images.length;
    if (showMaxImageCount > 3) {
      showMaxImageCount = 4;
    }
    projectImages = project.node.frontmatter.images.slice(0, showMaxImageCount);
  }

  return (
    <div className="project-item mb-6 col-span-1 md:col-span-1">
      <Link
        to={`/project/${slug}/`}
        className={"w-full block shadow-xs hover:shadow-lg rounded-xl bg-white"}
        style={{ backgroundColor: "white" }}
      >
        <div className="aspect-ratio-box ratio-16-9">
          {showImages &&
            projectImages.map((image, i) =>
              i == 0 ? (
                <Img
                  fixed={image.image && image.image.childImageSharp && image.image.childImageSharp.fluid}
                  className="aspect-ratio-box-inside w-full h-full rounded-tl-xl rounded-tr-xl"
                  style={{
                    maxWidth: "585px",
                    maxHeight: "390px",
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : null
            )}
        </div>
        <div className={"innerpadding"}>
          <h2 className="font-semibold mb-1 text-xl md:text-2xl pr-8">
            {title}
          </h2>
          {city && zip && (
            <p className="flex items-center">
              <div className="">
                <img src="/assets/location-pin.svg" className="w-2" />
              </div>
              <div className="ml-2">
                {city}, CA {zip}
              </div>
            </p>
          )}
          {excerpt ? <p className="excerpt">{excerpt}</p> : null}
        </div>
      </Link>
    </div>
  );
}

export default RecentProjectTile;
