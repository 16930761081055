import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import RecentProjectTile from "../components/RecentProjectTile";
import Breadcrumbs from "../components/Breadcrumbs";

function ProjectsPage() {
  return (
    <StaticQuery
      query={graphql`
        query getProjects {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "project-page" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  slug
                  title
                  description
                  city
                  zip 
                  images {
                    imgtitle
                    image {
                      childImageSharp {
                        fluid(maxWidth: 270, maxHeight: 165, quality: 70) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                        fixed(width: 270, height: 165, quality: 100) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
 
        let allProjectsData = [ 
          ...new Map(
            data.allMarkdownRemark.edges.map((item) => [item.node.id, item])
          ).values(),
        ];

        return (
          <Layout className="bg-gray-50" bottomBorder={true}>
            <SEO
              title={"Recent Projects | Bay Area Siding Company"}
              description={"Recent Projects"}
            />
            <div className="bg-white border-b border-gray-100">
              <nav
                className="flex container mx-auto px-4 py-3"
                aria-label="Breadcrumb"
              >
                <Breadcrumbs
                  links={[
                    { to: "/", label: "Home" },
                    { to: "/projects", label: "Projects" },
                  ]}
                />
              </nav>
            </div>
            <section className="w-full px-4 md:px-6 py-6 container mx-auto">
              <h1 className="text-2xl md:text-3xl font-bold mb-1">
                Recent Projects
              </h1>
              <div className="py-4 grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6">
                {allProjectsData.map((project, p) => (
                  <RecentProjectTile key={p} project={project} />
                ))}
              </div>
            </section>
          </Layout>
        );
      }}
    />
  );
}

export default ProjectsPage;
